import React from 'react';

import { Flex } from '@chakra-ui/react';

const HSeparator = ({ ...rest }: React.ComponentProps<typeof Flex>) => {
  return <Flex h="1px" w="100%" bg="rgba(135, 140, 189, 0.3)" {...rest} />;
};

const VSeparator = ({ ...rest }: React.ComponentProps<typeof Flex>) => {
  return <Flex w="1px" bg="rgba(135, 140, 189, 0.3)" {...rest} />;
};

export { HSeparator, VSeparator };
