import * as React from 'react';
import { useEffect } from 'react';

import {
  Box,
  Divider,
  Flex,
  IconButton,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import { ICouple, ICouplesList } from 'shared/model/couples';
import Card from 'components/card/Card';

import { formatDate, formatTime } from '../../../../../shared/utils';
import TableLoader from '../../../../../shared/ui/Table/TableLoader';
import TableNoData from '../../../../../shared/ui/Table/TableNoData';

const columnHelper = createColumnHelper<ICouple>();

interface ICouplesTableProps {
  tableData: ICouplesList;
  loading: boolean;
  error: boolean;
  changePage: (page: number) => void;
}

export const CouplesTable = ({ tableData, loading, error, changePage }: ICouplesTableProps) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue('secondaryGray.900', 'gray.300');

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const { t } = useTranslation('translation');
  const [data, setData] = React.useState<ICouple[]>([...(tableData.items || [])]);

  useEffect(() => {
    setData([...(tableData.items || [])]);
  }, [tableData.items]);

  const columns = [
    columnHelper.accessor('pairId', {
      id: 'ID',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`couple:id`)}
        </Text>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`couple:createdAt`)}
        </Text>
      ),
      cell: info => {
        return (
          <Flex align="center" direction="row">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {formatDate(info.getValue())}
            </Text>
            <Text>&nbsp;</Text>
            <Text color="gray.400" fontSize="sm" fontWeight="700">
              {formatTime(info.getValue(), false)}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('offerMale', {
      id: 'male',
      header: () => (
        <Text
          justifyContent="space-between"
          textAlign="center"
          w="100%"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`couple:male`)}
        </Text>
      ),
      cell: info => (
        <Box display="flex" alignItems="center" flexFlow="column">
          <Image src={info.getValue().photo} w="40px" />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue().firstName}
          </Text>
        </Box>
      ),
    }),

    columnHelper.accessor('offerMale', {
      id: 'male_age',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`couple:male_age`)}
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
          {info.getValue().age}
        </Text>
      ),
    }),

    columnHelper.accessor('offerFemale', {
      id: 'female',
      header: () => (
        <Text
          justifyContent="space-between"
          textAlign="center"
          w="100%"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`couple:female`)}
        </Text>
      ),
      cell: info => (
        <Box display="flex" alignItems="center" flexFlow="column">
          <Image src={info.getValue().photo} w="40px" />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue().firstName}
          </Text>
        </Box>
      ),
    }),

    columnHelper.accessor('offerFemale', {
      id: 'female_age',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`couple:female_age`)}
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
          {info.getValue().age}
        </Text>
      ),
    }),

    columnHelper.accessor('offerFemale', {
      id: 'compatibility',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`couple:compatibility`)}
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
          {info.getValue().compatibility.value}/{info.getValue().compatibility.maxValue}
        </Text>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error) {
    return (
      <Flex justify="center" py={5}>
        <Text>Error</Text>
      </Flex>
    );
  }

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{
        sm: 'scroll',
        lg: 'hidden',
      }}
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px" layout="auto">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{
                          sm: '10px',
                          lg: '12px',
                        }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {loading ? (
            <TableLoader columnsLength={columns.length} />
          ) : !tableData.items?.length ? (
            <TableNoData columnsLength={columns.length} />
          ) : (
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr key={row.id} cursor="pointer">
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{
                            sm: '150px',
                            md: '200px',
                            lg: 'auto',
                          }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </Box>
      <Divider />
      <Flex justifyContent="space-between" alignItems="center" py={3} px="24px">
        <Box flexGrow={1}>
          <IconButton
            aria-label="First page"
            icon={<MdOutlineKeyboardDoubleArrowLeft />}
            onClick={() => changePage(1)}
            isDisabled={tableData.pagination?.currentPage === 1}
            size="lg"
          />
          <IconButton
            aria-label="prev page"
            icon={<MdOutlineKeyboardArrowLeft />}
            onClick={() => changePage(tableData.pagination?.prevPage)}
            isDisabled={!tableData.pagination?.prevPage}
            size="lg"
          />
          <Box display="inline-block" px={3} color="gray.400">
            {tableData.pagination?.currentPage}
          </Box>
          <IconButton
            aria-label="next page"
            icon={<MdOutlineKeyboardArrowRight />}
            onClick={() => changePage(tableData.pagination?.nextPage)}
            isDisabled={!tableData.pagination?.nextPage}
            size="lg"
          />
          <IconButton
            aria-label="Last page"
            icon={<MdOutlineKeyboardDoubleArrowRight />}
            onClick={() => changePage(tableData.pagination?.lastPage)}
            isDisabled={
              tableData.pagination?.lastPage && tableData.pagination?.currentPage === tableData.pagination?.lastPage
            }
            size="lg"
          />
        </Box>
        <Flex direction="row" align="center">
          <Text color="gray.400" fontSize="sm" fontWeight="700" pr={5} whiteSpace="nowrap">
            {t('common:items_on_page')}
          </Text>
          {/* <Select
            sx={{ '> option': { color: 'gray.400' } }}
            value={perPage || 20}
            onChange={e => changePerPage(parseInt(e.target.value))}
          >
            {[10, 20, 30, 50, 100].map(item => (
              <option key={`items-per-page-${item}`} value={item}>
                <Text color="gray.400">{item}</Text>
              </option>
            ))}
          </Select> */}
        </Flex>
      </Flex>
    </Card>
  );
};
