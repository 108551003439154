import * as React from 'react';

import { Flex, Tbody, Td, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ITableNoDataProps {
  columnsLength: number;
}

const TableNoData = ({ columnsLength }: ITableNoDataProps) => {
  const { t } = useTranslation();

  return (
    <Tbody>
      <Tr>
        <Td colSpan={columnsLength}>
          <Flex justify="center" alignItems="center" width="100%" py="100px">
            {t('common:no_data')}
          </Flex>
        </Td>
      </Tr>
    </Tbody>
  );
};

export default TableNoData;
