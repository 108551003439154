import React from 'react';

import Chart from 'react-apexcharts';

type ChartProps = {
  chartData: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chartOptions: ApexCharts.ApexOptions;
};
type ChartState = {
  chartData: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chartOptions: ApexCharts.ApexOptions;
};

class ColumnChart extends React.Component<ChartProps, ChartState> {
  constructor(props: ChartProps) {
    super(props);
    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  }

  render() {
    return (
      <Chart options={this.state.chartOptions} series={this.state.chartData} type="bar" width="100%" height="100%" />
    );
  }
}

export default ColumnChart;
