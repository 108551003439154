import i18n from 'i18next';
import { initReactI18next, IResources } from 'react-i18next';

// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import { Language } from 'shared/model';

import ruLocale from '../../../locales/ru/translation.json';
import enLocale from '../../../locales/en/translation.json';
import { isDevEnv } from '../../../config';

const resources: IResources = {
  [Language.RU]: ruLocale,
  [Language.EN]: enLocale,
};

export const translator = i18n
  // .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    debug: isDevEnv,
    defaultNS: 'translation',
    fallbackLng: Language.RU,
    supportedLngs: Object.values(Language),
    interpolation: {
      escapeValue: false,
    },
  });
