/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';

import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { NavItem } from '../../../navigation';
import { useTranslation } from 'react-i18next';

export function SidebarLinks(props: { navItems: NavItem[] }) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  let activeIcon = useColorModeValue('brand.500', 'white');
  let textColor = useColorModeValue('secondaryGray.500', 'white');
  let brandColor = useColorModeValue('brand.500', 'brand.400');
  const { t } = useTranslation();

  const { navItems } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    // return location.pathname.includes(routeName);
    return location.pathname === routeName;
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (navItems: NavItem[]) => {
    return navItems
      .filter(item => item.sidebar)
      .map((route: NavItem, index: number) => {
        if (route.layout === '/admin' || route.layout === '/auth') {
          return (
            <Box key={`${route.path}-${index}-box`}>
              <NavLink key={`${route.path}-${index}`} to={(route.layout === '/admin' ? '' : route.layout) + route.path}>
                {route.icon ? (
                  <Box>
                    <HStack spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'} py="5px" ps="10px">
                      <Flex w="100%" alignItems="center" justifyContent="center">
                        <Box color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor} me="18px">
                          {route.icon}
                        </Box>
                        <Text
                          me="auto"
                          color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
                          fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
                        >
                          {t(`pages:${route.name}`)}
                        </Text>
                      </Flex>
                      <Box
                        h="36px"
                        w="4px"
                        bg={activeRoute(route.path.toLowerCase()) ? brandColor : 'transparent'}
                        borderRadius="5px"
                      />
                    </HStack>
                  </Box>
                ) : (
                  <Box>
                    <HStack spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'} py="5px" ps="10px">
                      <Text
                        me="auto"
                        color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
                        fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
                      >
                        {t(`pages:${route.name}`)}
                      </Text>
                      <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                    </HStack>
                    {route.children && <Box pl={5}>{createLinks(route.children)}</Box>}
                  </Box>
                )}
              </NavLink>
              {route.children && (
                <Box key={`child-${route.path}-${index}`} pl={5}>
                  {createLinks(route.children)}
                </Box>
              )}
            </Box>
          );
        }
      });
  };
  //  BRAND
  return <>{createLinks(navItems)}</>;
}

export default SidebarLinks;
