import { Flex } from '@chakra-ui/react';

type IconBoxProps = {
  icon: JSX.Element | string;
} & React.ComponentProps<typeof Flex>;

export default function IconBox({ icon, ...rest }: IconBoxProps) {
  return (
    <Flex alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} {...rest}>
      {icon}
    </Flex>
  );
}
