import { useEffect } from 'react';

import { Box, Card, Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import usersStore from 'stores/usersStore';

import { DeletedUsersTable } from './ui';

export const DeletedUsersPage = () => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { fetchDeletedUsers, deletedUsers, deletedUserFilter, deletedUsersLoading, changeDeletedPage, changeDeletedPerPage } = usersStore();
  const { t } = useTranslation('translation');

  useEffect(() => {
    fetchDeletedUsers();
  }, []);

  return (
    <Card
      flexDirection="column"
      w="100%"
      overflowX={{
        sm: 'scroll',
        lg: 'hidden',
      }}
      mt={{
        base: '80px',
        md: '80px',
        xl: '80px',
      }}
    >
      <Box
        pt={{
          base: '10px',
          md: '10px',
          xl: '10px',
        }}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text color={textColor} lineHeight="100%">
            {t('user:total')}: {deletedUsers.pagination?.total}
          </Text>
          {/* <FilterBar isLoading={loading} defaultStatus={filter.profileStatus} onChange={handleFilterChange} /> */}
        </Flex>
      </Box>
      <Divider />
      <Box
        pt={{
          base: '10px',
          md: '10px',
          xl: '10px',
        }}
      >
        <DeletedUsersTable deletedUsers={deletedUsers} filter={deletedUserFilter} loading={deletedUsersLoading} changePage={changeDeletedPage} changePerPage={changeDeletedPerPage} />
      </Box>
    </Card>
  );

};