import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import ToastError from 'shared/ui/ToastError';
import ToastSuccess from 'shared/ui/ToastSuccess';

import ToastWarning from '../../ui/ToastWarning';

export const throwErrorToast = (title: string, message?: string) => {
  toast.error(
    <div className="w-335p h-74p text-14 relative flex items-center right-10 text-14 text-white py-4 pr-5 pl-20 bg-red rounded-24p">
      <ToastError className="absolute top-2 left-2" />
      <span className="w-210p whitespace-pre-line">{title}</span>
      {message && <span className="leading-5 pt-3 whitespace-pre-wrap">{message}</span>}
    </div>,
    {
      icon: false,
      theme: 'colored',
    }
  );
};
export const throwSuccessToast = (title: string, message?: string) => {
  toast.success(
    <div className="w-335p h-74p whitespace-pre-wrap flex items-center text-white text-14 relative right-10 py-5 pr-5 pl-20 bg-green rounded-24p">
      <ToastSuccess className="absolute top-2 left-2" />
      <h4 className="w-210p">{title}</h4>
      {message && <span className="leading-5 mt-3 inline-block">{message}</span>}
    </div>,
    {
      icon: false,
      theme: 'colored',
    }
  );
};

export const throwWarningToast = (title: string, message?: string) => {
  toast.warning(
    <Flex w="355px" h="74px" position="relative" whiteSpace="pre-wrap" alignItems="center" color="white" p={5}>
      <ToastWarning pr={4} />
      <h4 className="w-210p">{title}</h4>
      {message && <span className="leading-5 mt-3 inline-block">{message}</span>}
    </Flex>,
    {
      icon: false,
      theme: 'colored',
    }
  );
};
