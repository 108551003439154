import { IAuthUser } from '../model/auth';

import { apiInstance } from './base';

// export type AuthLoginResponse  = {
//   data: AuthUser | null,
//   message?: string
// }

export async function Login(login: string, password: string): Promise<IAuthUser> {
  const response = await apiInstance.post('/api/v1/admin/login', { login: login, password: password });

  return response.data;
}
