import format from 'date-fns/format';

/**
 * Formats a given date into a string representation using the format 'dd.MM.yyyy'.
 *
 * @param {Date | string} date - The date to be formatted. Can be either a Date object or a string.
 * @return {string} The formatted date as a string in the format 'dd.MM.yyyy'.
 */
export function formatDate(date: Date | string) {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  return date ? format(date, 'dd.MM.yyyy') : '--.--.----';
}
/**
 * Formats a given date into a string representation of the format 'dd.MM.yyyy'.
 *
 * @param {Date | string} date - The date to be formatted. It can be either a Date object or a string in a valid date format.
 * @return {string} The formatted date string in the format 'dd.MM.yyyy'.
 */
export function formatDateTime(date: Date | string) {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  return format(date, 'dd.MM.yyyy HH:mm:ss');
}

/**
 * Formats a given date or string into a time string.
 *
 * @param {Date | string} date - The date or string to be formatted.
 * @param {boolean} withSeconds - Whether to include the seconds in the formatted time. Default is true.
 * @return {string} The formatted time string.
 */
export function formatTime(date: Date | string, withSeconds = true) {
  try {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return format(date, withSeconds ? 'HH:mm:ss' : 'HH:mm');
  } catch (e) {
    console.warn(`Error format time [${Date}]`, e);

    return `--:--${withSeconds ? ':--' : ''}`;
  }
}
