import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

import { fetchCouples } from 'shared/api/couples';
import { ICouplesList } from 'shared/model/couples';

export interface ICouplesStore {
  couplesData: ICouplesList;
  loading: boolean;
  error: boolean;
}

export interface ICouplesStoreActions {
  fetchPairs: (page?: number) => void;
  changePage: (page: number) => void;
}

export const useCouplesStore = create<ICouplesStore & ICouplesStoreActions>()(
  devtools(
    persist(
      (set, get) => ({
        couplesData: {} as ICouplesList,
        loading: false,
        error: false,
        fetchPairs: async page => {
          try {
            set(state => ({
              ...state,
              loading: true,
            }));
            const couplesData = await fetchCouples(page);
            set(state => ({
              ...state,
              couplesData,
            }));
          } catch (error) {
            set(state => ({
              ...state,
              error: true,
            }));
          } finally {
            set(state => ({
              ...state,
              loading: false,
            }));
          }
        },
        changePage: async (page: number) => {
          await get().fetchPairs(page);
        },
      }),
      {
        name: 'jojo-admin-users',
        storage: createJSONStorage(() => localStorage),
        // partialize: state => ({ filter: state.filter }),
      }
    )
  )
);
