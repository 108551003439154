import React, { useEffect } from 'react';

import { Box, Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { t } from 'i18next';

import { useCouplesStore } from 'stores/couplesStore';

import Card from '../../../components/card/Card';

import { CouplesTable } from './ui';

export const CouplesPage = () => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const { fetchPairs, couplesData, loading, error, changePage } = useCouplesStore();

  useEffect(() => {
    fetchPairs();
  }, []);

  return (
    <Card
      flexDirection="column"
      w="100%"
      overflowX={{
        sm: 'scroll',
        lg: 'hidden',
      }}
      mt={{
        base: '80px',
        md: '80px',
        xl: '80px',
      }}
    >
      <Box
        pt={{
          base: '10px',
          md: '10px',
          xl: '10px',
        }}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text color={textColor} lineHeight="100%">
            {t('user:total')}: {couplesData.pagination?.total}
          </Text>
          {/* <FilterBar isLoading={loading} defaultStatus={filter.profileStatus} onChange={handleFilterChange} /> */}
        </Flex>
      </Box>
      <Divider />
      <Box
        pt={{
          base: '10px',
          md: '10px',
          xl: '10px',
        }}
      >
        <CouplesTable
          tableData={couplesData}
          loading={loading}
          error={error}
          changePage={changePage}
        />
      </Box>
    </Card>
  );
};
