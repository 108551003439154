import React, { FC } from 'react';

import { MdWarning } from 'react-icons/md';
import { Icon } from '@chakra-ui/react';

interface IToastProps {
  className?: string;
}

const ToastError: FC<IToastProps> = () => {
  const classes = '';

  return (
    <div className={classes}>
      <Icon as={MdWarning} w="24px" h="24px" mt="4px" />
    </div>
  );
};

export default ToastError;
