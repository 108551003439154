import { useState } from 'react';

import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

import useUsersStore from '../../../../stores/usersStore';

export const AgeSlider = () => {
  const [age, setAge] = useState<number[]>([18, 99]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { filter, setFilter } = useUsersStore();

  const handleAgeChange = (ageRange: number[]) => {
    setAge(ageRange);
  };

  const handleAgeChangeEnd = (ageRange: number[]) => {
    setFilter({ ...filter, ageFrom: ageRange[0], ageTo: ageRange[1] });
  };

  return (
    <>
      <Text color={textColor} lineHeight="100%" marginBottom="10px">
        Возраст: {age[0]} - {age[1]}
      </Text>
      <RangeSlider defaultValue={[18, 99]} min={18} max={99} step={1} onChangeEnd={handleAgeChangeEnd} onChange={handleAgeChange}>
        <RangeSliderTrack bg='blue.100'>
          <RangeSliderFilledTrack bg='tomato' />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={6} index={0} />
        <RangeSliderThumb boxSize={6} index={1} />
      </RangeSlider>
    </>
  );
};