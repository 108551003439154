import * as React from 'react';
import { useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { isEqual } from 'lodash';

import { IUsersListRequest } from 'shared/api/users';

import useUsersStore from '../../../stores/usersStore';
import Card from '../../../components/card/Card';

import UsersTable from './components/UsersTable';
import { ControlBar } from './components/ControlBar';

export default function Users() {
  const state = useUsersStore();
  const { users, filter, loading, error, fetchUsers, changePage, changePerPage, setSorting } = state;

  const [prevFilter, setPrevFilter] = React.useState<IUsersListRequest | null>(filter);

  useEffect(() => {
    // prevent fetch users when return to page
    if (isEqual(users, {}) || !isEqual(prevFilter, filter)) {
      fetchUsers(filter);
    }
    setPrevFilter(filter);
  }, [filter, fetchUsers, users, prevFilter]);

  return (
    <Card
      flexDirection="column"
      w="100%"
      overflowX={{
        sm: 'scroll',
        lg: 'hidden',
      }}
      mt={{
        base: '80px',
        md: '80px',
        xl: '80px',
      }}
    >
      <ControlBar />
      <Box
        pt={{
          base: '10px',
          md: '10px',
          xl: '10px',
        }}
      >
        <>
          <UsersTable
            tableData={users}
            loading={loading}
            error={error}
            changePage={changePage}
            changePerPage={changePerPage}
            setSorting={setSorting}
            perPage={filter.limit || 20}
            sortingField={filter.sortBy}
            sortingOrder={filter.sortOrder}
          />
        </>
      </Box>
    </Card>
  );
}
