import React, { ReactNode, useState } from 'react';

import { Box, Button, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import { CheckCircleIcon, EditIcon } from '@chakra-ui/icons';

import { ICustomCardProps } from 'theme/theme';
import Card from 'components/card/Card';

import { IIdNameCode } from '../../../../shared/model';

type InformationVariants = 'default' | 'searcher' | 'vedic';

type InformationProps = {
  title: string;
  variant?: InformationVariants;
  value: number | string | ReactNode | IIdNameCode | IIdNameCode[];
  valueAlign?: 'right' | 'left' | 'center';
  isEditable?: boolean;
  editCallback?: (value: string) => void;
} & Partial<Omit<ICustomCardProps, 'variant'>>;

export default function Information({
  title,
  value,
  variant = 'default',
  valueAlign = 'right',
  isEditable,
  editCallback,
  ...rest
}: InformationProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editableValue, setEditableValue] = useState((value || '-').toString());
  const colors = {
    default: useColorModeValue('white', 'navy.700'),
    searcher: useColorModeValue('cyan.50', 'navy.700'),
    vedic: useColorModeValue('purple.50', 'navy.700'),
  };
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  // const bg = useColorModeValue('white', 'navy.700');

  let displayValue: string | ReactNode = null;

  const handleEditClick = () => {
    if (isEditing && editCallback && (value || value?.toString() !== editableValue)) {
      editCallback(editableValue);
    }
    setIsEditing(!isEditing);
  };

  const editValue: React.ChangeEventHandler<HTMLTextAreaElement> = event => setEditableValue(event.target.value);

  if (value) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        if (value.every(v => Object.prototype.hasOwnProperty.call(v, 'name'))) {
          value.map(v => v.name).join(', ');
        } else {
          displayValue = value.join(', ');
        }
      } else {
        displayValue = null;
      }
    } else {
      if (React.isValidElement(value)) {
        displayValue = value;
      } else {
        displayValue =
          typeof value === 'object' && value !== null && 'name' in value ? value.name : (value as ReactNode);
      }
    }
  }

  return (
    <Card bg={colors[variant]} boxShadow={cardShadow} {...rest}>
      <Box>
        <Box display="flex" justifyContent="space-between" fontWeight="500" color={textColorSecondary} fontSize="sm">
          <Text>
            {title}
          </Text>

          {isEditable && (
            <Button onClick={handleEditClick} colorScheme='teal' variant='link'>
              {isEditing ? <CheckCircleIcon /> : <EditIcon />}
            </Button>
          )}
        </Box>
        <Box color={textColorPrimary} fontWeight="500" fontSize="md" textAlign={valueAlign}>
          {isEditable && isEditing ?
            (<Textarea value={editableValue} onChange={editValue}></Textarea>) :
            isEditable ? editableValue : displayValue || '-'
          }
        </Box>
      </Box>
    </Card>
  );
}
