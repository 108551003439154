import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

import { fetchDeletedUsers, fetchUsers, IDeletedUsersListFilter, IUsersListRequest } from '../shared/api/users';
import { IDeletedUsersList, IUsersList } from '../shared/model/user';

export interface IUsersStore {
  users: IUsersList;
  filter: IUsersListRequest;
  loading: boolean;
  error: boolean;

  deletedUsersLoading: boolean;
  deletedUsersError: boolean;

  deletedUsers: IDeletedUsersList;
  deletedUserFilter: IDeletedUsersListFilter;
}

export interface IUsersStoreActions {
  fetchUsers: (users: IUsersListRequest) => void;
  changePage: (compareId: number) => void;
  setFilter: (filter: IUsersListRequest) => void;
  changePerPage: (onePageItems: number) => void;
  setSorting: (sortingField: string) => void;
  fetchDeletedUsers: (filter?: IDeletedUsersListFilter) => void;
  changeDeletedPage: (page: number) => void;
  changeDeletedPerPage: (perPage: number) => void;
}

const usersStore = create<IUsersStore & IUsersStoreActions>()(
  devtools(
    persist(
      (set, get) => ({
        users: {} as IUsersList,
        filter: {} as IUsersListRequest,
        loading: false,
        error: false,
        deletedUsersLoading: false,
        deletedUsersError: false,
        deletedUsers: {} as IDeletedUsersList,
        deletedUserFilter: {} as IDeletedUsersListFilter,
        fetchUsers: async (filter: IUsersListRequest) => {
          try {
            set(state => ({
              ...state,
              loading: true,
            }));
            const users = await fetchUsers(filter);
            const usersWithDetailedBalance = users.items.map(item => ({
              ...item,
              detailedBalance: `${item.balance}/${item.meetingsWithPurchase}/${item.meetingsWithBlackDoor}`,
            }));
            set(state => ({
              ...state,
              users: { ...users, items: usersWithDetailedBalance },
              filter,
            }));
          } catch (error) {
            set(state => ({
              ...state,
              error: true,
            }));
          } finally {
            set(state => ({
              ...state,
              loading: false,
            }));
          }
        },
        changePage: async (page: number) => {
          await get().fetchUsers({ ...get().filter, page: page });
        },
        setFilter: (filter: IUsersListRequest) => {
          set(state => ({
            ...state,
            filter: {
              ...filter,
              //compareId: ''
            },
          }));
        },
        changePerPage: (perPage: number) => {
          set(state => ({
            ...state,
            filter: {
              ...state.filter,
              limit: perPage,
              page: 1,
            },
          }));
        },
        setSorting: async (sortingField: string) => {
          console.log('sortingField123', sortingField);
          set(state => ({
            ...state,
            filter: {
              ...state.filter,
              sortBy: sortingField,
              sortOrder: state.filter.sortBy === sortingField && state.filter.sortOrder === 'asc' ? 'desc' : 'asc',
            },
          }));
        },
        fetchDeletedUsers: async filter => {
          try {
            set(state => ({
              ...state,
              deletedUsersLoading: true,
              deletedUsersError: false,
            }));
            const filtersFinal = filter ? filter : get().deletedUserFilter;
            set(state => ({ ...state, deletedUserFilter: filtersFinal }));
            const users = await fetchDeletedUsers(filtersFinal);

            set(state => ({
              ...state,
              deletedUsers: users,
            }));

            set(state => ({
              ...state,
              deletedUsersLoading: false,
            }));
          } catch (error) {
            set(state => ({
              ...state,
              deletedUsersError: true,
              deletedUsersLoading: false,
            }));
          }
        },

        changeDeletedPage: async (page: number) => {
          await get().fetchDeletedUsers({ ...get().deletedUserFilter, page: page });
        },

        changeDeletedPerPage: async (perPage: number) => {
          await get().fetchDeletedUsers({ ...get().deletedUserFilter, limit: perPage });
        },
      }),
      {
        name: 'jojo-admin-users',
        storage: createJSONStorage(() => localStorage),
        partialize: state => ({ filter: state.filter }),
      }
    )
  )
);

export default usersStore;
