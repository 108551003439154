import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { Box, Button, Checkbox, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import userStore from 'stores/userStore';

type UserCommentsType = 'isInvalidPhotos' | 'isBlankProfile' | 'isInvalidAge' | 'yourChance';

//TODO move to corresponding place
export type UserComments = {
  [key in UserCommentsType]: boolean;
};

interface IModerationBlockProps {
  isModeration: boolean;
}

const defaultComments: UserComments = {
  isInvalidPhotos: false,
  isBlankProfile: false,
  isInvalidAge: false,
  yourChance: false,
};

export const ModerationBlock = ({ isModeration }: IModerationBlockProps) => {
  const { id } = useParams();
  const { commentUpdateLoading, rejectModeration, commentUser, approveModeration, loading: moderationLoading } = userStore();
  const [comments, setComments] = useState(defaultComments);
  const [hasNewComments, setHasNewComments] = useState(false);
  const { t } = useTranslation();

  const handleCommentCheck = (comment: UserCommentsType) => () => {
    setHasNewComments(true);
    setComments({ ...comments, [comment]: !comments[comment] });
  };

  const handleApproveModeration = () => {
    approveModeration(parseInt(id));
  };

  const handleReject = () => {
    rejectModeration(parseInt(id));
  };

  const handleCommentSubmit = () => {
    commentUser(parseInt(id), comments);
  };

  return (
    <Stack alignItems="flex-end" justifyContent="space-between">
      <Stack spacing={5} direction="row">
        <Checkbox colorScheme="red" onChange={handleCommentCheck('isInvalidPhotos')} isDisabled={commentUpdateLoading}>
          {t('user:comments:invalidPhotos')}
        </Checkbox>
        <Checkbox colorScheme="red" onChange={handleCommentCheck('isBlankProfile')} isDisabled={commentUpdateLoading}>
          {t('user:comments:blankProfile')}
        </Checkbox>
        <Checkbox colorScheme="red" onChange={handleCommentCheck('yourChance')} isDisabled={commentUpdateLoading}>
          {t('user:comments:yourChance')}
        </Checkbox>
        <Button
          colorScheme="brandScheme"
          onClick={handleCommentSubmit}
          isLoading={commentUpdateLoading}
          isDisabled={!hasNewComments}
        >
          {t('common:submit')}
        </Button>
      </Stack>
      {isModeration && (
        <Box maxW="250px" display="flex" justifyContent="space-between" gap={5}>
          <Button colorScheme="green" onClick={handleApproveModeration} isLoading={moderationLoading}>
            {t('moderation:approve')}
          </Button>
          <Button colorScheme="red" onClick={handleReject} isLoading={moderationLoading}>
            {t('moderation:reject')}
          </Button>
        </Box>
      )}
    </Stack>
  );
};
