import * as React from 'react';

import { SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Information from 'views/admin/users/components/Information';
import { ICustomCardProps } from 'theme/theme';
import Card from 'components/card/Card';
import userStore from 'stores/userStore';

import { IUser } from '../../../../shared/model';

type UserProfileBlockProps = {
  user: IUser;
} & Partial<ICustomCardProps>;

export default function UserProfileBlock({ user, ...rest }: UserProfileBlockProps) {
  const { t } = useTranslation();
  const { editBio } =
    userStore();
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const handleBioEdit = (bio: string) => {
    editBio(bio);
  };

  if (JSON.stringify(user) === JSON.stringify({})) {
    return null;
  }

  return (
    <Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
        Профиль ({user.profile?.filling}%)
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2, '2xl': 4 }} gap="20px">
        <Information title="О себе" value={user.profile?.bio} valueAlign="left" gridRow={{ base: '1', md: '1/3' }} editCallback={handleBioEdit} isEditable />
        <Information title="Образование" value={user.profile?.education} />
        <Information title="Профессиональный статус" value={user.profile?.professionalStatus} />
        <Information title="Сфера деятельности" value={user.profile?.sphere} />
        <Information title={t('user:zodiac')} value={t(`user:zodiac_signs.${user.profile?.zodiac}`)} />
        <Information title="Вероисповедание" value={user.profile?.religion} />
        <Information title="Планы на семью" value={user.profile?.familyPlan} />
        <Information title="Вакцина от COVID" value={user.profile?.covid} />
        <Information title="Курение" value={user.profile?.smokeStatus} />
        <Information title="Алкоголь" value={user.profile?.alcoholStatus} />
        <Information title="Предпочтения в еде" value={user.profile?.foodPreference} />
        <Information title="Тренировки" value={user.profile?.workout} />
        <Information title="Привычки во сне" value={user.profile?.sleepHabit} />
        <Information title="Питомцы" value={user.profile?.pets.map(({ name }) => name).join(', ')} />
        <Information title="Идеальное место встречи" value={user.profile?.idealMeetingPoints.map(({ name }) => name).join(', ')} />
        <Information title="Интересы" value={user.profile?.interests.map(({ name }) => name).join(', ')} />
        <Information title="Языки" value={user.profile?.languages.map(({ name }) => name).join(', ')} />
      </SimpleGrid>
    </Card>
  );
}
