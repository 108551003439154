import { Dispatch, KeyboardEventHandler, SetStateAction, useState } from 'react';

import {
  Box,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from '@chakra-ui/react'
import { SearchIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { t } from 'i18next';

import useUsersStore from '../../../../stores/usersStore';

export const TextFilters = () => {
  const { filter, loading, setFilter } = useUsersStore();

  const [userId, setUserId] = useState(filter.userId || '');
  const [email, setEmail] = useState(filter.email || '');
  const [cityOfLiving, setCityOfLiving] = useState(filter.locationCity || '');
  const [cityBorn, setCityBorn] = useState(filter.cityBorn || '');

  const handleUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleCityOfLivingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCityOfLiving(event.target.value);
  };

  const handleCityOfBornChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCityBorn(event.target.value);
  };

  const getRightInputWidth = (value: string) => value.length > 0 ? '80px' : '40px';

  const handleInputSearch = (filterName: string, value: string) => () => setFilter({ ...filter, [filterName]: value });

  const handleInputClear = (setter: Dispatch<SetStateAction<string>>, filterName: string) => () => {
    setter('');
    setFilter({ ...filter, [filterName]: '' });
  }

  const handleEnterPress: (filterName: string, value: string) => KeyboardEventHandler<HTMLInputElement> = (filterName, value) => event => {
    if (event.key === 'Enter') {
      setFilter({ ...filter, [filterName]: value });
    }
  };

  return (
    <>
      <Box display="flex">
        <InputGroup size='md' mr="10px">
          <Input
            placeholder='User ID'
            disabled={loading}
            value={userId}
            onChange={handleUserIdChange}
            onKeyUp={handleEnterPress('userId', userId)}
          />
          <InputRightElement w={getRightInputWidth(userId)}>
            {!!userId && <Button size='sm' onClick={handleInputClear(setUserId, 'userId')} isLoading={loading}>
              <SmallCloseIcon />
            </Button>}
            <Button size='sm' onClick={handleInputSearch('userId', userId)} isLoading={loading}>
              <SearchIcon />
            </Button>
          </InputRightElement>
        </InputGroup>

        <InputGroup size='md'>
          <Input
            placeholder={t('common:cityOfLiving')}
            disabled={loading}
            value={cityOfLiving}
            onChange={handleCityOfLivingChange}
            onKeyUp={handleEnterPress('locationCity', cityOfLiving)}
          />
          <InputRightElement w={getRightInputWidth(cityOfLiving)}>
            {!!cityOfLiving && <Button size='sm' onClick={handleInputClear(setCityOfLiving, 'locationCity')} isLoading={loading}>
              <SmallCloseIcon />
            </Button>}
            <Button size='sm' onClick={handleInputSearch('locationCity', cityOfLiving)} isLoading={loading}>
              <SearchIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
      <br />
      <Box display="flex">

        <InputGroup size='md' mr="10px">
          <Input
            placeholder='Email'
            disabled={loading}
            value={email}
            onChange={handleEmailChange}
            onKeyUp={handleEnterPress('email', email)}
          />
          <InputRightElement w={getRightInputWidth(email)}>
            {!!email && <Button size='sm' onClick={handleInputClear(setEmail, 'email')} isLoading={loading}>
              <SmallCloseIcon />
            </Button>}
            <Button size='sm' onClick={handleInputSearch('email', email)} isLoading={loading}>
              <SearchIcon />
            </Button>
          </InputRightElement>
        </InputGroup>

        <InputGroup size='md'>
          <Input
            placeholder={t('common:cityOfBirth')}
            disabled={loading}
            value={cityBorn}
            onChange={handleCityOfBornChange}
            onKeyUp={handleEnterPress('cityBorn', cityBorn)}
          />
          <InputRightElement w={getRightInputWidth(cityBorn)}>
            {!!cityBorn && <Button size='sm' onClick={handleInputClear(setCityBorn, 'cityBorn')} isLoading={loading}>
              <SmallCloseIcon />
            </Button>}
            <Button size='sm' onClick={handleInputSearch('cityBorn', cityBorn)} isLoading={loading}>
              <SearchIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
    </>
  );
};