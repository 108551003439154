import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { IAuthUser } from 'shared/model/auth';

import { Login } from '../shared/api';

export interface IAuthState {
  user: IAuthUser;
  loading: boolean;
  error: boolean;
}

export interface IAuthStoreActions {
  login: (login: string, password: string, callback: () => void) => void;
  logout: () => void;
  getToken: () => string | null;
}

const authStore = create<IAuthState & IAuthStoreActions>()(
  devtools(
    immer(
      persist(
        (set, get) => ({
          user: {} as IAuthUser,
          loading: false,
          error: false,
          login: async (login, password, callback) => {
            try {
              set(state => {
                state.loading = true;
                state.error = false;
              });
              const user = await Login(login, password);
              if (user?.accessToken) {
                set(state => {
                  state.loading = false;
                  state.user = user;
                });
                callback();
              } else {
                set(state => {
                  state.loading = false;
                  state.error = true;
                });
              }
            } catch (error) {
              set(state => {
                state.error = true;
                state.loading = false;
              });
            }
          },

          logout: () => {
            set(state => {
              state.user = {} as IAuthUser;
            });
          },

          getToken(): string | null {
            return get().user?.accessToken ?? null;
          },
        }),
        {
          name: 'jja',
          storage: createJSONStorage(() => localStorage),
          partialize: state => ({ user: state.user }),
        }
      )
    )
  )
);

export default authStore;
