import { Flex, useColorModeValue } from '@chakra-ui/react';

import { JOJOLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
  //   Chakra color mode
  const logoColor = useColorModeValue('#242424', 'white');

  return (
    <Flex alignItems="center" flexDirection="column">
      <JOJOLogo color={logoColor} />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
