import { useEffect, useState } from 'react';

import { Grid, GridItem } from '@chakra-ui/react';

import { IUser } from 'shared/model';

import userStore from '../../../../stores/userStore';
import UserShortOfferTable from '../components/UserShortOffersTable';

interface IUserProfileTabProps {
  user: IUser;
}

export function UserOffersTab({ user }: IUserProfileTabProps) {
  const { shortOffersList, loadingShortOffersList, errorShortOffersList, fetchShortOffersList } = userStore();
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    fetchShortOffersList(user.userId, currentPage);
  }, [user.userId, currentPage, fetchShortOffersList]);

  const changePage = (page: number) => {
    setCurrentPage(page);
    fetchShortOffersList(user.userId, page);
  };

  if (loadingShortOffersList) {
    return <p>Loading...</p>;
  }

  if (errorShortOffersList) {
    return <p>Error :( </p>;
  }

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr',
        lg: '1fr',
        '2xl': '1fr',
      }}
      gap={{
        base: '20px',
        xl: '20px',
      }}
    >
      <GridItem colSpan={{ base: 1, lg: 1 }}>
        <UserShortOfferTable
          tableData={shortOffersList}
          loading={loadingShortOffersList}
          error={errorShortOffersList}
          changePage={changePage}
          changePerPage={() => false}
        />
      </GridItem>
    </Grid>
  );
}

export default UserOffersTab;
