import { Box, Text, useColorModeValue } from '@chakra-ui/react';

import { ICustomCardProps } from 'theme/theme';
import Card from 'components/card/Card';

type IInformationProps = {
  title: string;
  value: string;
} & Partial<ICustomCardProps>;

export default function Information({ title, value, ...rest }: IInformationProps) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const bg = useColorModeValue('white', 'navy.700');

  return (
    <Card bg={bg} {...rest}>
      <Box>
        <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
          {title}
        </Text>
        <Text color={textColorPrimary} fontWeight="500" fontSize="md">
          {value}
        </Text>
      </Box>
    </Card>
  );
}
