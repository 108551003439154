import * as React from 'react';

import { HTMLChakraProps, Icon, useColorModeValue } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { MdFemale, MdMale, MdQuestionMark } from 'react-icons/md';

import { Gender } from '../../model/user';

type GenderIconProps = {
  gender: Gender;
} & Omit<HTMLChakraProps<'svg'>, 'orientation'>;

export const GenderIcon = ({ gender, boxSize, ...rest }: GenderIconProps) => {
  const genderIconColor = useColorModeValue('gray.400', 'gray.400');
  const size = 10;
  let icon: IconType;
  switch (gender) {
    case 'male':
      icon = MdMale;
      break;
    case 'female':
      icon = MdFemale;
      break;
    default:
      icon = MdQuestionMark;
  }

  return <Icon as={icon} boxSize={boxSize || size} {...rest} color={genderIconColor} />;
};
