import React from 'react';

import './assets/css/Fonts.css';
import './assets/css/App.css';
import 'shared/lib/i18n';
import { ChakraProvider } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';

import App from './app';
import theme from './theme/theme';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>
);
