import React from 'react';

import { Box, Flex, Icon, Progress, Text, useColorModeValue } from '@chakra-ui/react';
import { MdOutlineCloudDone } from 'react-icons/md';

import Card from 'components/card/Card';
import IconBox from 'components/icons/IconBox';
import Menu from 'components/menu/MainMenu';

interface IBannerProps {
  used: number;
  total: number;
  gridArea: { base: string; lg: string };
}

export default function Banner({ used, total }: IBannerProps) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const textColorSecondary = 'gray.400';
  const box = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems="center">
      <Flex w="100%">
        <Menu ms="auto" />
      </Flex>
      <IconBox
        mx="auto"
        h="100px"
        w="100px"
        icon={<Icon as={MdOutlineCloudDone} color={brandColor} h="46px" w="46px" />}
        bg={box}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px">
        Your storage
      </Text>
      <Text color={textColorSecondary} fontSize="md" maxW={{ base: '100%', xl: '80%', '3xl': '60%' }} mx="auto">
        Supervise your drive space in the easiest way
      </Text>
      <Box w="100%" mt="auto">
        <Flex w="100%" justify="space-between" mb="10px">
          <Text color={textColorSecondary} fontSize="sm" maxW="40%">
            {used} GB
          </Text>
          <Text color={textColorSecondary} fontSize="sm" maxW="40%">
            {total} GB
          </Text>
        </Flex>
        <Progress alignItems="start" colorScheme="brandScheme" value={(used / total) * 100} w="100%" />
      </Box>
    </Card>
  );
}
