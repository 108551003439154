import { Box, Card, Divider, Flex, IconButton, Select, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

import TableNoData from "shared/ui/Table/TableNoData";
import { Gender, IDeletedUsersList, IDeletedUsersListItem } from "shared/model/user";
import { IDeletedUsersListFilter } from "shared/api/users";
import TableLoader from "shared/ui/Table/TableLoader";

const columnHelper = createColumnHelper<IDeletedUsersListItem>();

interface IDeletedUsersTableProps {
  deletedUsers: IDeletedUsersList;
  filter: IDeletedUsersListFilter;
  loading: boolean;
  changePage: (page: number) => void;
  changePerPage: (perPage: number) => void;
}

export const DeletedUsersTable = ({ deletedUsers, filter, loading, changePage, changePerPage }: IDeletedUsersTableProps) => {
  const textColor = useColorModeValue('secondaryGray.900', 'gray.300');
  const { t } = useTranslation('translation');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const rowColors = {
    male: useColorModeValue('cyan.50', 'cyan.900/33'),
    maleHover: useColorModeValue('cyan.100', 'cyan.900/66'),
    female: useColorModeValue('pink.50', 'pink.900/66'),
    femaleHover: useColorModeValue('pink.100', 'pink.900/99'),
  };

  console.log('deletedUsers', deletedUsers);


  const columns = [
    columnHelper.accessor('id', {
      id: 'ID',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:id`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('deviceSystem', {
      id: 'deviceSystem',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:os`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('applicationVersion', {
      id: 'applicationVersion',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:applicationVersion`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:selectionCountry`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('age', {
      id: 'age',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:age`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('gender', {
      id: 'gender',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:gender`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('sourceChannel', {
      id: 'sourceChannel',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:channel`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('purchasesBalanceTotal', {
      id: 'purchasesBalanceTotal',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:meetingsBought`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('showedOffersTotal', {
      id: 'showedOffersTotal',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:offersShown`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('pugetFillingPercent', {
      id: 'pugetFillingPercent',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:pugetFillingPercent`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('telegramId', {
      id: 'telegramId',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:banReason`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('deletionReasons', {
      id: 'deletionReasons',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:deletionReasons`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue().length ? info.getValue().map(({ name }) => name).join(', ') : '-.-'}
          </Text>
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: deletedUsers.items,
    columns,
    state: {
      columnVisibility: { id: false },
    },
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  return deletedUsers && (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{
        md: 'scroll',
        lg: 'hidden',
      }}
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px" layout="auto">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{
                          sm: '10px',
                          lg: '12px',
                        }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {loading ? (
            <TableLoader columnsLength={columns.length} />
          ) : !deletedUsers.items?.length ? (
            <TableNoData columnsLength={columns.length} />
          ) : (
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr
                    key={row.id}
                    cursor="pointer"
                    backgroundColor={rowColors[row.getValue('gender') as Gender]}
                    sx={{ '&:hover': { backgroundColor: rowColors[`${row.getValue('gender') as Gender}Hover`] } }}
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{
                            sm: '150px',
                            md: '200px',
                            lg: 'auto',
                          }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </Box>
      <Divider />
      <Flex justifyContent="space-between" alignItems="center" py={3} px="24px">
        <Box flexGrow={1}>
          <IconButton
            aria-label="First page"
            icon={<MdOutlineKeyboardDoubleArrowLeft />}
            onClick={() => changePage(1)}
            isDisabled={deletedUsers.pagination?.currentPage === 1}
            size="lg"
          />
          <IconButton
            aria-label="prev page"
            icon={<MdOutlineKeyboardArrowLeft />}
            onClick={() => changePage(deletedUsers.pagination?.prevPage)}
            isDisabled={!deletedUsers.pagination?.prevPage}
            size="lg"
          />
          <Box display="inline-block" px={3} color="gray.400">
            {deletedUsers.pagination?.currentPage}
          </Box>
          <IconButton
            aria-label="next page"
            icon={<MdOutlineKeyboardArrowRight />}
            onClick={() => changePage(deletedUsers.pagination?.nextPage)}
            isDisabled={!deletedUsers.pagination?.nextPage}
            size="lg"
          />
          <IconButton
            aria-label="Last page"
            icon={<MdOutlineKeyboardDoubleArrowRight />}
            onClick={() => changePage(deletedUsers.pagination?.lastPage)}
            isDisabled={
              deletedUsers.pagination?.lastPage && deletedUsers.pagination?.currentPage === deletedUsers.pagination?.lastPage
            }
            size="lg"
          />
        </Box>
        <Flex direction="row" align="center">
          <Text color="gray.400" fontSize="sm" fontWeight="700" pr={5} whiteSpace="nowrap">
            {t('common:items_on_page')}
          </Text>
          <Select
            sx={{ '> option': { color: 'gray.400' } }}
            value={filter.limit || 20}
            onChange={e => changePerPage(parseInt(e.target.value))}
          >
            {[10, 20, 30, 50, 100].map(item => (
              <option key={`items-per-page-${item}`} value={item} color="gray.400">
                {item}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
    </Card>
  );
};