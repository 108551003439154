import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { t } from 'i18next';

import useUsersStore from '../../../../stores/usersStore';

import { FilterBar } from './FilterBar';
import { AgeSlider } from './AgeSlider';
import { TextFilters } from './TextFilters';

export const ControlBar = () => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { users } = useUsersStore();

  return (
    <Box
      pt={{
        base: '10px',
        md: '10px',
        xl: '10px',
      }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color={textColor} lineHeight="100%">
          {t('user:total')}: {users.pagination?.total}
        </Text>
        <FilterBar />
      </Flex>

      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as='span' flex='1' textAlign='left'>
                {t('common:additionalFilters')}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <AgeSlider />
            <TextFilters />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )

};