import * as React from 'react';

import { Flex, Tbody, Td } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ITableErrorProps {
  columnsLength: number;
  error?: string;
}

const TableError = ({ columnsLength, error }: ITableErrorProps) => {
  const { t } = useTranslation();

  return (
    <Tbody>
      <Td colSpan={columnsLength}>
        <Flex justify="center" alignItems="center" width="100%" py="100px">
          {error || t('common:load_error')}
        </Flex>
      </Td>
    </Tbody>
  );
};

export default TableError;
