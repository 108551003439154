export enum Language {
  EN = 'en',
  RU = 'ru',
}

export type Languages = `${Language}`;
export interface ILangMap<DType extends string> extends Partial<Record<Languages, DType>> {
  default?: DType;
}

export interface IPagination {
  currentPage: number;
  lastPage: number;
  nextPage?: number;
  nextPageUrl?: string | null;
  perPage: number;
  prevPage?: number;
  prevPageUrl?: string | null;
  total: number;
}

export interface ILocation {
  coordinate: ICoordinate;
  city?: string;
}

export interface ICoordinate {
  latitude: string;
  longitude: string;
}

export interface IIdNameCode {
  id: number;
  name: string;
  code: string;
}

export type { IUser, IUsersList, IUsersListItem } from './user';
export type { IOffer, IShortOfferItem, IShortOffersList } from './offers';
