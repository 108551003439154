import { Fragment, useEffect } from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { IUser } from 'shared/model';

import userStore from '../../../../stores/userStore';
import UserPhotoBlock from '../components/UserPhotoBlock';
import UserGeneral from '../components/UserGeneralBlock';
import UserProfileAlt from '../components/UserProfileBlock';

interface IUserProfileTabProps {
  user: IUser;
}

export function UserProfileTab({ user }: IUserProfileTabProps) {
  const { id } = useParams();
  const { loading, error, fetchUser } =
    userStore();
  const userPhotos = user.profile?.photos;

  useEffect(() => {
    //TODO investigate why we have 2 calls here
    fetchUser(parseInt(id));

    window.scrollTo({ top: 0 });
  }, [id]);

  const isOnModeration = (!loading && user.profile?.status === 'moderation') || user.profile?.status === 'reModeration';

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr 1fr',
        lg: '1fr 1fr',
        '2xl': '1.34fr 1.62fr 1fr',
      }}
      gap={{
        base: '20px',
        xl: '20px',
      }}
    >
      {loading && <p>Loading...</p>}
      {error && !loading && <p>Loading...</p>}
      {!!user && !loading && !error && (
        <Fragment>
          <GridItem colSpan={{ base: 1, md: 2, lg: 3 }}>
            <UserGeneral user={user} isModeration={isOnModeration} />{' '}
          </GridItem>
          <UserPhotoBlock photos={userPhotos} biometric={user.biometric} examination={user.examination} />
          {/*<UserPhotoControlBlock user={user}/>*/}
          <GridItem colSpan={{ base: 1, lg: 2 }}>
            <UserProfileAlt user={user} colSpan={2} />
          </GridItem>
        </Fragment>
      )}
    </Grid>
  );
}

export default UserProfileTab;
