import { useState } from 'react';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'assets/css/MiniCalendar.css';
import { Text, Icon } from '@chakra-ui/react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Value } from 'react-calendar/dist/cjs/shared/types';

import { ICustomCardProps } from 'theme/theme';
import Card from 'components/card/Card';

type MiniCalendarProps = {
  selectRange?: boolean;
} & Partial<ICustomCardProps>;

export default function MiniCalendar({ selectRange, ...rest }: MiniCalendarProps) {
  const [value, onChange] = useState(new Date());
  const onDateChange = (value: Value) => {
    onChange(value as Date);
  };

  return (
    <Card
      alignItems="center"
      flexDirection="column"
      w="100%"
      maxW="max-content"
      p="20px 15px"
      h="max-content"
      {...rest}
    >
      <Calendar
        onChange={onDateChange}
        value={value}
        selectRange={selectRange}
        view={'month'}
        tileContent={<Text color="brand.500" />}
        prevLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
        nextLabel={<Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />}
      />
    </Card>
  );
}
