import { useState } from 'react';

import { find } from 'lodash';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { t } from 'i18next';

import { Caste, Gender, ProfileStatus, SearchStatus } from 'shared/model/user';

import useUsersStore from '../../../../stores/usersStore';

//TODO create separate file for models
interface IOption {
  label: string;
  value: ProfileStatus | SearchStatus | Gender | Caste | 'all';
}

const profileStatuses: IOption[] = [
  {
    label: t('common:all'),
    value: 'all',
  },
  {
    label: t('user:statuses:register'),
    value: 'register',
  },
  {
    label: t('user:statuses:moderation'),
    value: 'moderation',
  },
  {
    label: t('user:statuses:active'),
    value: 'active',
  },
  {
    label: t('user:statuses:frozen'),
    value: 'frozen',
  },
  {
    label: t('user:statuses:rejected'),
    value: 'rejected',
  },
  {
    label: t('user:statuses:reModeration'),
    value: 'reModeration',
  },
  {
    label: t('user:statuses:deleted'),
    value: 'deleted',
  },
];

// const searchStatuses: IOption[] = [
//   {
//     label: t('common:all'),
//     value: 'all',
//   },
//   {
//     label: t('user:searchStatuses:active'),
//     value: 'active',
//   },
//   {
//     label: t('user:searchStatuses:pending'),
//     value: 'pending',
//   },
//   {
//     label: t('user:searchStatuses:rejected'),
//     value: 'rejected',
//   },
// ];

const genders: IOption[] = [
  {
    label: t('common:all'),
    value: 'all',
  },
  {
    label: t('user:genders:male'),
    value: 'male',
  },
  {
    label: t('user:genders:female'),
    value: 'female',
  },
];


const castes: IOption[] = [
  {
    label: t('common:all'),
    value: 'all',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
];


export const FilterBar = () => {
  const { filter, loading, setFilter } = useUsersStore();

  const [profileStatus, setProfileStatus] = useState(find(profileStatuses, { value: filter.profileStatus }) || profileStatuses[0]);
  // const [searchStatus, setSearchStatus] = useState(find(searchStatuses, { value: filter.searcherStatus }) || searchStatuses[0]);
  const [gender, setGender] = useState(find(genders, { value: filter.gender }) || genders[0]);
  const [caste, setCaste] = useState(find(genders, { value: filter.caste }) || castes[0]);

  const handleProfileStatusChange = (option: IOption) => {
    setProfileStatus(option);
    setFilter({ ...filter, profileStatus: (option.value === 'all' ? '' : option.value) as ProfileStatus });
  };

  // const handleSearchStatusChange = (option: IOption) => {
  //   setSearchStatus(option);
  //   setFilter({ ...filter, searcherStatus: (option.value === 'all' ? '' : option.value) as SearchStatus });
  // };

  const handleGenderChange = (option: IOption) => {
    setGender(option);
    setFilter({ ...filter, gender: (option.value === 'all' ? '' : option.value) as Gender });
  };

  const handleCasteChange = (option: IOption) => {
    setCaste(option);
    setFilter({ ...filter, caste: (option.value === 'all' ? '' : option.value) as Caste });
  };

  return (
    <Box display="flex">
      {/* <Box w="250px" mr="10px">
        <FormControl>
          <FormLabel>First name</FormLabel>
          <Select isLoading={loading} value={searchStatus} options={searchStatuses} onChange={handleSearchStatusChange} />
        </FormControl>
      </Box> */}
      <Box w="250px" mr="10px">
        <FormControl>
        <FormLabel>{t('common:caste')}</FormLabel>
          <Select isLoading={loading} value={caste} options={castes} onChange={handleCasteChange} />
        </FormControl>
      </Box>
      <Box w="250px" mr="10px">
        <FormControl>
          <FormLabel>{t('user:gender')}</FormLabel>
          <Select isLoading={loading} value={gender} options={genders} onChange={handleGenderChange} />
        </FormControl>
      </Box>
      <Box w="250px">
        <FormControl>
        <FormLabel>{t('user:status')}</FormLabel>
          <Select isLoading={loading} value={profileStatus} options={profileStatuses} onChange={handleProfileStatusChange} />
        </FormControl>
      </Box>
    </Box>
  );
};
