import { Avatar, AvatarBadge, Badge, Box, Flex, SimpleGrid, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ICustomCardProps } from 'theme/theme';
import Information from 'views/admin/users/components/Information';
import Card from 'components/card/Card';
import SwitchField from 'components/fields/SwitchField';

import { IUser } from '../../../../shared/model';
import { NO_IMAGE } from '../../../../config';
import { formatDate, formatTime } from '../../../../shared/utils';
import { GenderIcon } from '../../../../shared/ui/GenderIcon';
import { PlatformIcon } from '../../../../shared/ui/PlatformIcon';
import { StatusSearchIcon } from '../../../../shared/ui/StatusSearchIcon';

import { ModerationBlock } from './ModerationBlock';

type GeneralInformationProps = {
  user: IUser;
  isModeration?: boolean;
} & Partial<ICustomCardProps>;

export default function GeneralInformation({ user, isModeration = false, ...rest }: GeneralInformationProps) {
  const { t } = useTranslation();
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';

  if (JSON.stringify(user) === JSON.stringify({})) {
    return null;
  }

  const getAvatar = (user: IUser) => {
    return user.profile?.photos?.filter(photo => photo.isCover)?.[0]?.image ?? NO_IMAGE;
  };

  const iconColor = user.searcher?.gender === 'male' ? '#6CDDF1' : '#F581A2';

  const getSearchStatusIcon = (user: IUser) => {
    let color: string;
    switch (user.searcher?.searchStatus) {
      case 'active':
        color = 'green.600';
        break;
      case 'pending':
        color = 'yellow.400';
        break;
      case 'rejected':
        color = 'red.700';
        break;
      default:
        color = 'gray.600';
    }

    return (
      <AvatarBadge borderColor="gray.200" borderWidth=".05em" _dark={{ borderWidth: 0 }} bg={color} boxSize=".8em">
        <StatusSearchIcon searchStatus={user.searcher?.searchStatus} color="white" />
      </AvatarBadge>
    );
  };

  return (
    <Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
      <Flex pl="25px" mb="8px" justifyContent="space-between" align="flex-start">
        <Box display="flex" flexDirection="row" mb={5} w="100%">
          <Avatar size={{ base: 'xl', lg: '2xl' }} src={getAvatar(user)}>
            {getSearchStatusIcon(user)}
          </Avatar>

          <Box pl={6} maxW="85%" display="flex" flexDirection="column" flexGrow={1} h="100%">
            <Box color={textColorSecondary} fontSize="md" verticalAlign="middle" pl={4}>
              #{user.userId} ({formatDate(user.createdAt)})
            </Box>
            <Box display="flex" color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
              <GenderIcon gender={user.searcher?.gender} fill={iconColor} />
              <Box>
                <Text as="span" overflowWrap="anywhere" textOverflow="ellipsis">
                  {user.profile?.firstName}
                </Text>
                &nbsp;<Text as="span">({user.searcher?.age})</Text>
              </Box>
            </Box>
            <Box ml={4}>
              <Text as="span" color={textColorSecondary}>
                {' '}
                E-mail:
              </Text>
              <Text as="span" color={textColorPrimary}>
                {' '}
                {user.account?.email ?? '-'}{' '}
              </Text>
            </Box>
            <Flex pl={4} mt={4} align="center">
              <Tooltip label="Статус акаунта">
                <Badge variant={user.profile?.status}>{t(`user:statuses.${user.profile?.status}`)}</Badge>
              </Tooltip>
              <Tooltip label={`Платформа: ${user.os}`}>
                <Box>
                  <PlatformIcon os={user.os} color={textColorSecondary} ml={3} />
                </Box>
              </Tooltip>
              <Tooltip label="Телеграм бот">
                <Badge ml="1em" as="span" variant={user.isTelegramBotActive ? 'telegramBotOn' : 'telegramBotOff'}>
                  TG
                </Badge>
              </Tooltip>
            </Flex>
          </Box>

          <ModerationBlock isModeration={isModeration} />
        </Box>
      </Flex>

      <SimpleGrid columns={{ base: 2, md: 2, lg: 3, '2xl': 5 }} gap="20px">
        <Information
          title="Город подбора"
          value={
            <>
              <Box>{user.searcher?.location.city ?? '-'}</Box>
              {user.searcher?.location.coordinate && (
                <Box>
                  ({user.searcher?.location.coordinate.latitude}, {user.searcher?.location.coordinate.longitude})
                </Box>
              )}
            </>
          }
        />
        <Information title="Ищет (возраст)" value={`${user.preference?.ageFrom} — ${user.preference?.ageTo}`} />
        <Information
          title="Ищет (рост)"
          value={`${user.preference?.heightFrom ?? '---'} —  ${user.preference?.heightTo ?? '---'}`}
        />
        <Information title="Рост" value={user.searcher?.height} />
        <Information title="Возраст" value={user.searcher?.age} />
        <Information title="Каста" value={user.searcher?.caste} />
        <Information title={t('user:pickupTime')} value={t(`user:pickupTime_values.${user.searcher?.pickupTime}`)} />
        <Information
          title="Ведический подбор"
          value={
            <SwitchField id="agreeForVedic" readonly={true} variant="info" isChecked={user.searcher?.agreeForVedic} />
          }
        />
        <Information
          title="Место рождения "
          value={
            <>
              <Box>{user.vedic?.birthLocation.city ?? '-'}</Box>
              {user.vedic?.birthLocation.coordinate && (
                <Box>
                  ({user.vedic?.birthLocation.coordinate.latitude}, {user.vedic?.birthLocation.coordinate.longitude})
                </Box>
              )}
            </>
          }
        />

        <Information
          title="Дата и время рождения"
          value={
            <>
              <Box>{formatDate(user.profile?.birthday)}</Box>
              {user.vedic?.birthtime && <Box>{formatTime(`1970-01-01 ${user.vedic?.birthtime}`, false)}</Box>}
              <Box>{user.vedic?.timezoneBorn}</Box>
            </>
          }
        />

        <Information
          title={t(`user:meetingsBought`)}
          value={
            <Box>{user.balance}</Box>
          }
        />
      </SimpleGrid>
    </Card>
  );
}
