import * as React from 'react';

import { ComponentWithAs, HTMLChakraProps, Icon, IconProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { MdPause, MdPlayArrow, MdQuestionMark, MdStop } from 'react-icons/md';


type SearchStatusIconProps = {
  searchStatus: string | null;
} & Omit<HTMLChakraProps<'svg'>, 'orientation'>;

export const StatusSearchIcon = ({ searchStatus, boxSize, ...rest }: SearchStatusIconProps) => {
  const size = 5;
  let icon: IconType | ComponentWithAs<'svg', IconProps>;
  switch (searchStatus) {
    case 'active':
      icon = MdPlayArrow;
      break;
    case 'pending':
      icon = MdPause;
      break;
    case 'rejected':
      icon = MdStop;
      break;
    default:
      icon = MdQuestionMark;
  }

  return <Icon as={icon} boxSize={boxSize || size} {...rest} />;
};
