import React from 'react';

import { RouterProvider } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import { router } from '../routes';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        transition={Slide}
        closeButton={false}
        pauseOnFocusLoss={false}
      />
    </>
  );
}
