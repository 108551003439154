import { Box, Flex, Stack } from '@chakra-ui/react';

//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';

// import SidebarCard from 'components/sidebar/components/SidebarCard';
import { NavItem } from '../../../navigation';

// FUNCTIONS

function SidebarContent(props: { navItems: NavItem[] }) {
  const { navItems } = props;

  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand />
      <Stack direction="column" mt="8px" mb="auto">
        <Box ps="20px" pe={{ lg: '16px', '2xl': '16px' }}>
          <Links navItems={navItems} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
