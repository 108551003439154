import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { IOffer } from '../shared/model';
import { fetchOffer } from '../shared/api/users';

export interface IOfferStore {
  offer: IOffer;
  loading: boolean;
  error: boolean;
}

export interface IOfferStoreActions {
  fetchOffer: (id: number) => void;
}

const offerStore = create<IOfferStore & IOfferStoreActions>()(
  devtools(
    immer(set => ({
      offer: {} as IOffer,
      loading: false,
      error: false,
      fetchOffer: async (id: number) => {
        try {
          set(state => {
            state.loading = true;
          });
          const offer = await fetchOffer(id);
          set(state => {
            state.offer = offer;
            state.loading = false;
          });
        } catch (error) {
          set(state => {
            state.error = true;
            state.loading = false;
          });
        }
      },
    }))
  )
);

export default offerStore;
