import { useState } from 'react';

import { useOutlet } from 'react-router-dom';
import { Box, useColorModeValue } from '@chakra-ui/react';

import { SidebarContext } from 'contexts/SidebarContext';

export default function Auth() {
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const outlet = useOutlet();
  /*
	const getRoute = () => {
		return window.location.pathname !== '/auth/full-screen-maps';
	};
*/

  /*const getRoutes = (
		routes: IRoutesType[]
	): any => {
		return routes.map(
			(
				route: IRoutesType,
				key: any
			) => {
				if (route.layout === '/auth') {
					return <Route path={route.layout + route.path} component={route.component} key={key} />;
				} else {
					return null;
				}
			}
		);
	};
	*/

  const authBg = useColorModeValue('white', 'navy.900');
  document.documentElement.dir = 'ltr';

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          bg={authBg}
          float="right"
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box mx="auto" minH="100vh">
            {outlet}
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
