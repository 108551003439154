import { StyleFunctionProps, mode } from '@chakra-ui/theme-tools';

export const badgeStyles = {
  components: {
    Badge: {
      baseStyle: {
        borderRadius: '10px',
        lineHeight: '100%',
        padding: '7px',
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      variants: {
        outline: () => ({
          borderRadius: '16px',
        }),
        brand: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('brand.500', 'brand.400')(props),
          color: 'white',
          _focus: {
            bg: mode('brand.500', 'brand.400')(props),
          },
          _active: {
            bg: mode('brand.500', 'brand.400')(props),
          },
          _hover: {
            bg: mode('brand.600', 'brand.400')(props),
          },
        }),
        active: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('green.200', 'green.300')(props),
          color: mode('gray.600', 'gray.800')(props),
        }),
        frozen: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('yellow.200', 'yellow.300')(props),
          color: mode('gray.600', 'gray.800')(props),
        }),
        register: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('orange.200', 'orange.400')(props),
          color: mode('gray.600', 'gray.800')(props),
        }),
        moderation: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('purple.200', 'purple.400')(props),
          color: mode('gray.600', 'gray.800')(props),
        }),
        reModeration: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('blue.200', 'blue.400')(props),
          color: mode('gray.600', 'gray.800')(props),
        }),
        deleted: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('gray.200', 'gray.400')(props),
          color: mode('gray.600', 'gray.800')(props),
        }),
        rejected: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('gray.200', 'gray.400')(props),
          color: mode('gray.600', 'gray.800')(props),
        }),
        pending: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('teal.200', 'teal.400')(props),
          color: mode('gray.600', 'gray.800')(props),
        }),
        telegramBotOn: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('blue.500', 'blue.500')(props),
          color: mode('gray.100', 'gray.100')(props),
        }),
        telegramBotOff: (props: Record<string, unknown> | StyleFunctionProps) => ({
          bg: mode('gray.500', 'gray.500')(props),
          color: mode('gray.100', 'gray.100')(props),
        }),
      },
    },
  },
};
