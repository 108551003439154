import { SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';

import Information from 'views/admin/profile/components/Information';
import { ICustomCardProps } from 'theme/theme';
import Card from 'components/card/Card';

export default function GeneralInformation({ ...rest }: Partial<ICustomCardProps>) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  return (
    <Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
        General Information
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        As we live, our hearts turn colder. Cause pain is what we go through as we become older. We get insulted by
        others, lose trust for those others. We get back stabbed by friends. It becomes harder for us to give others a
        hand. We get our heart broken by people we love, even that we give them all...
      </Text>
      <SimpleGrid columns={2} gap="20px">
        <Information boxShadow={cardShadow} title="Education" value="Stanford University" />
        <Information boxShadow={cardShadow} title="Languages" value="English, Spanish, Italian" />
        <Information boxShadow={cardShadow} title="Department" value="Product Design" />
        <Information boxShadow={cardShadow} title="Work History" value="Google, Facebook" />
        <Information boxShadow={cardShadow} title="Organization" value="Simmmple Web LLC" />
        <Information boxShadow={cardShadow} title="Birthday" value="20 July 1986" />
      </SimpleGrid>
    </Card>
  );
}
