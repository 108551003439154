import { useState } from 'react';

import {
  Portal,
  Box,
  useDisclosure,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue,
} from '@chakra-ui/react';
import { Navigate, NavLink, UIMatch, useMatches, useOutlet, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SidebarContext } from 'contexts/SidebarContext';
import authStore from 'stores/authStore';
import Sidebar from 'components/sidebar/Sidebar';
import Navbar from 'components/navbar/NavbarAdmin';
import Footer from 'components/footer/FooterAdmin';

import { NavItem, NavItems } from '../../navigation';


export default function Admin() {
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const outlet = useOutlet();
  const { pathname } = useLocation();
  const { t } = useTranslation('translation');
  const secondaryText = useColorModeValue('gray.700', 'white');
  const { user } = authStore();
  console.log('🧑 User', user);
  const matches = useMatches();

  // functions for changing the states from components
  const getActiveRoute = () => {
    return matches[matches.length - 1].id;
    // return (
    //   routes.find((route) => {
    //     return matchPath(route.path, pathname);
    //   })?.name || 'JOJO Admin'
    // );
  };

  const getActiveNavbar = (): boolean => {
    return false;
    /*return routes.find(route => {
            return matchPath(route.path, pathname);
        })?.secondary || false;*/
  };

  const getActiveNavbarText = (pathname: string, routes: NavItem[]) => {
    return routes.find(route => {
      return matchPath(route.path, pathname);
    })?.name;
  };

  const getBreadcrumbs = (matches: UIMatch[]) => {
    if (matches.length < 3) {
      return null;
    }
    if (matches[2].pathname === matches[1].pathname.concat('/')) {
      return null;
    }

    return (
      <Box pb={4}>
        <Breadcrumb>
          {/* <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
            <BreadcrumbLink href="#" color={secondaryText}>
              Pages
            </BreadcrumbLink>
          </BreadcrumbItem> */}

          <BreadcrumbItem color={secondaryText} fontSize="sm">
            <BreadcrumbLink as="span" color={secondaryText}>
              <NavLink to={`${matches[1].pathname}`}>{t(`pages:${matches[1].id}`)}</NavLink>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
    );
  };

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();

  if (!user.accessToken) {
    console.log('need redirect');

    return <Navigate to="/auth/sign-in" />;
  }

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar navItems={NavItems} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={'JOJO Admin'}
                breadcrumbs={getBreadcrumbs(matches)}
                brandText={getActiveRoute()}
                secondary={getActiveNavbar()}
                message={getActiveNavbarText(pathname, NavItems)}
                fixed={fixed}
              />
            </Box>
          </Portal>

          <Box mx="auto" p={{ base: '20px', md: '30px' }} pe="20px" minH="100vh" pt="50px">
            {outlet}
          </Box>

          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
