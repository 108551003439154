import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { throwErrorToast } from 'shared/lib/toastify';

import { API_URL, isDevEnv } from '../../config';
import authStore from '../../stores/authStore';

const logOnDev = (message: string) => {
  if (isDevEnv) {
    console.info(`⟹ ${message}`);
  }
};

// Потенциально, можно передавать accessToken
export const apiInstance = axios.create({
  baseURL: API_URL,
});

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  logOnDev(`[API] | Request [${JSON.stringify(config)}]`);
  const token = authStore.getState().getToken();
  if (!token) {
    return config;
  }
  config.headers['Authorization'] = `Bearer ${token}`;
  config.headers['Content-Type'] = `application/json`;

  return config;
};

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
  if (axios.isAxiosError(error)) {
    let { message } = error;
    const { method, url } = error.config as AxiosRequestConfig;
    const { status } = (error.response as AxiosResponse) ?? {};

    logOnDev(`[API] | Error [${method?.toUpperCase()} ${url} | Error ${status} ${message}]`);

    switch (status) {
      case 400: {
        // "Invalid request"
        message = `${error.response.data.message}`;
        // throwErrorToast(`${error.response.data.message}`);
        // console.log('==============>>>>', statusText, error.response, error.message);
        break;
      }

      case 401: {
        // "Login required"
        message = 'Необходимо авторизоваться';
        break;
      }
      case 403: {
        // "Permission denied"
        break;
      }
      case 404: {
        // "Invalid request"
        break;
      }
      case 429: {
        // "Invalid request"
        break;
      }
      case 500: {
        // "Server error"
        break;
      }
      default: {
        // "Unknown error occurred"
        break;
      }
    }

    if (status === 401) {
      // Delete Token & Go To Login Page if you required.
      // localStorage.removeItem("token");
      throwErrorToast(`${message}`);
      const { logout } = authStore.getState();
      logout();
      window.location.replace('/auth/sign-in');
    }
    throwErrorToast(`${message}`);
  } else {
    logOnDev(`[API] | Error [${error.message}]`);
  }

  return Promise.reject(error);
};

apiInstance.interceptors.request.use(onRequest, onErrorResponse);

apiInstance.interceptors.response.use(request => request?.data, onErrorResponse);
