import { Box, Flex, Text, Icon, useColorModeValue, Checkbox } from '@chakra-ui/react';
import { MdCheckBox, MdDragIndicator } from 'react-icons/md';

import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import IconBox from 'components/icons/IconBox';

export default function Conversion() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'navy.700');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  return (
    <Card p="20px" alignItems="center" flexDirection="column" w="100%">
      <Flex alignItems="center" w="100%" mb="30px">
        <IconBox
          me="12px"
          w="38px"
          h="38px"
          bg={boxBg}
          icon={<Icon as={MdCheckBox} color={brandColor} w="24px" h="24px" />}
        />

        <Text color={textColor} fontSize="lg" fontWeight="700">
          Tasks
        </Text>
        <Menu ms="auto" />
      </Flex>
      <Box px="11px" w="100%">
        <Flex w="100%" justify="space-between" mb="20px">
          <Checkbox me="16px" colorScheme="brandScheme" />
          <Text fontWeight="bold" color={textColor} fontSize="md" textAlign="start">
            Landing Page Design
          </Text>
          <Icon ms="auto" as={MdDragIndicator} color="secondaryGray.600" w="24px" h="24px" />
        </Flex>
        <Flex w="100%" justify="space-between" mb="20px">
          <Checkbox me="16px" defaultChecked colorScheme="brandScheme" />
          <Text fontWeight="bold" color={textColor} fontSize="md" textAlign="start">
            Dashboard Builder
          </Text>
          <Icon ms="auto" as={MdDragIndicator} color="secondaryGray.600" w="24px" h="24px" />
        </Flex>
        <Flex w="100%" justify="space-between" mb="20px">
          <Checkbox defaultChecked me="16px" colorScheme="brandScheme" />
          <Text fontWeight="bold" color={textColor} fontSize="md" textAlign="start">
            Mobile App Design
          </Text>
          <Icon ms="auto" as={MdDragIndicator} color="secondaryGray.600" w="24px" h="24px" />
        </Flex>
        <Flex w="100%" justify="space-between" mb="20px">
          <Checkbox me="16px" colorScheme="brandScheme" />
          <Text fontWeight="bold" color={textColor} fontSize="md" textAlign="start">
            Illustrations
          </Text>
          <Icon ms="auto" as={MdDragIndicator} color="secondaryGray.600" w="24px" h="24px" />
        </Flex>
        <Flex w="100%" justify="space-between" mb="20px">
          <Checkbox defaultChecked me="16px" colorScheme="brandScheme" />
          <Text fontWeight="bold" color={textColor} fontSize="md" textAlign="start">
            Promotional LP
          </Text>
          <Icon ms="auto" as={MdDragIndicator} color="secondaryGray.600" w="24px" h="24px" />
        </Flex>
      </Box>
    </Card>
  );
}
