import React from 'react';

import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdOutlineMoreHoriz } from 'react-icons/md';
import { IconType } from 'react-icons';

export interface IContextMenuItem {
  label: string;
  icon: IconType;
  onClick: () => void;
  disabled?: boolean;
}

type ContextMenuProps = {
  menuItems: IContextMenuItem[];
  id: string;
} & React.ComponentProps<typeof MenuButton>;

export default function ContextMenu({ menuItems, id, ...rest }: ContextMenuProps) {
  const textColor = useColorModeValue(
    { color: 'secondaryGray.500', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  );
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.300', bg: 'unset' }
  );
  const iconColor = useColorModeValue('brand.500', 'white');
  const iconColorDisabled = useColorModeValue('gray.500', 'gray.500');

  const bgList = useColorModeValue('white', 'whiteAlpha.100');
  const bgShadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
  const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();

  const isDisabled = !menuItems || menuItems.length === 0;

  return (
    <Menu isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        disabled={isDisabled}
        alignItems="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w="37px"
        h="37px"
        lineHeight="100%"
        onClick={onOpen1}
        borderRadius="10px"
        {...rest}
      >
        <Icon as={MdOutlineMoreHoriz} color={isDisabled ? iconColorDisabled : iconColor} w="24px" h="24px" />
      </MenuButton>
      <MenuList
        w="auto"
        minW="unset"
        maxW="350px !important"
        border="transparent"
        backdropFilter="blur(63px)"
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius="20px"
        p="15px"
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={`${id}-${index}`}
            transition="0.2s linear"
            color={textColor}
            onClick={item.onClick}
            _hover={textHover}
            w="auto"
            p="4px"
            borderRadius="8px"
            _active={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
            _disabled={{}}
            whiteSpace="nowrap"
            mb="10px"
          >
            <Flex align="center">
              <Icon as={item.icon} h="16px" w="16px" me="8px" />
              <Text fontSize="sm" fontWeight="400">
                {item.label}
              </Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
