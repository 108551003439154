import { createBrowserRouter, createRoutesFromElements, Outlet, Route } from 'react-router-dom';

import Profile from 'views/admin/profile';

import Auth from './layouts/auth';
import Admin from './layouts/admin';
import Default from './views/admin/default';
import Tables from './views/admin/dataTables';
import Users from './views/admin/users';
import SignIn from './views/auth/signIn';
import UserPage from './views/admin/users/user-page';
import { CouplesPage } from './views/admin/couples';
import { isDevEnv } from './config';
import { DeletedUsersPage } from './views/admin/deletedUsersPage';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route id="adminRoot" element={<Admin />}>
        {/*<Route id="home" index path="/" element={<Default/>}/>*/}
        <Route id="home" index path="/" element={isDevEnv ? <Default /> : <Users />} />
        <Route id="usersArea" path="/" element={<Outlet />}>
          <Route id="users" index path="/users" element={<Users />} />
          <Route id="user" path="/users/:id" element={<UserPage />} />
        </Route>
        <Route id="deletedUsers" index path="/deleted-users" element={<DeletedUsersPage />} />
        <Route id="couplesArea" path="/" element={<Outlet />}>
          <Route id="couples" index path="/couples" element={<CouplesPage />} />
          {/* <Route id="couple" path="/couple/:id" element={<CouplePage />} /> */}
        </Route>
        <Route path="/examples" element={<Outlet />}>
          <Route index path="/examples/tables" element={<Tables />} />
          <Route path="/examples/profile" element={<Profile />} />
        </Route>
      </Route>
      <Route element={<Auth />}>
        <Route path="/auth/sign-in" element={<SignIn />} />
      </Route>
    </>
  )
);
