import * as React from 'react';

import { Flex, Spinner, Tbody, Td, Tr } from '@chakra-ui/react';

interface ITableLoaderProps {
  columnsLength: number;
}

const TableLoader = ({ columnsLength }: ITableLoaderProps) => {
  return (
    <Tbody>
      <Tr>
        <Td colSpan={columnsLength}>
          <Flex justify="center" alignItems="center" width="100%" py="200px">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          </Flex>
        </Td>
      </Tr>
    </Tbody>
  );
};

export default TableLoader;
