import React, { FC } from 'react';

import { MdWarning } from 'react-icons/md';
import { Box, HTMLChakraProps, Icon } from '@chakra-ui/react';

interface IToastProps extends HTMLChakraProps<'div'> {}

const ToastWarning: FC<IToastProps> = ({ ...rest }) => {
  return (
    <Box {...rest}>
      <Icon as={MdWarning} w="24px" h="24px" mt="4px" />
    </Box>
  );
};

export default ToastWarning;
