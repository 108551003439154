import { Box, Card, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import TableNoData from "shared/ui/Table/TableNoData";
import { IUser } from "shared/model";
import { formatDateTime } from "shared/utils";
import { IBlockedUser } from "shared/model/user";

const columnHelper = createColumnHelper<IBlockedUser>();

interface IUserBlockedTabProps {
  user: IUser;
}

export const UserBlockedTab = ({ user }: IUserBlockedTabProps) => {
  const textColor = useColorModeValue('secondaryGray.900', 'gray.300');
  const { t } = useTranslation('translation');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');


  const columns = [
    columnHelper.accessor('userId', {
      id: 'ID',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:id`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:events:eventDate`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {formatDateTime(info.getValue())}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('firstName', {
      id: 'firstName',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:first_name`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('reasons', {
      id: 'reasons',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:banReason`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue().length ? info.getValue().join(', ') : '-'}
          </Text>
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: user.blockedByMe,
    columns,
    state: {
      columnVisibility: { id: false },
    },
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  return user && (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{
        sm: 'scroll',
        lg: 'hidden',
      }}
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px" layout="auto">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{
                          sm: '10px',
                          lg: '12px',
                        }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {!user.blockedByMe.length ? (
            <TableNoData columnsLength={columns.length} />
          ) : (
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr
                    key={row.id}
                    cursor="pointer"
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{
                            sm: '150px',
                            md: '200px',
                            lg: 'auto',
                          }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </Box>
    </Card>
  );
};