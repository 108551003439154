import { ICouplesList } from 'shared/model/couples';

import { apiInstance } from './base';

/**
 * Fetches users based on the provided filter.
 *
 * @return {Promise<ICouplesList>} - A promise that resolves to the fetched user data.
 */
export async function fetchCouples(page: number = 0): Promise<ICouplesList> {
  const response = await apiInstance.get(`/api/v1/admin/pairs?page=${page}`);

  return response.data;
}
