import { ReactNode } from 'react';

import { Icon } from '@chakra-ui/react';
import { MdHome, MdLayers, MdLock, MdPeople, MdVerifiedUser } from 'react-icons/md';
import { FaPeoplePulling, FaUsersSlash } from "react-icons/fa6";

import { isDevEnv } from './config';

export type NavItem = {
  name: string;
  layout: string;
  path: string;
  icon?: ReactNode;
  secondary?: boolean;
  sidebar?: boolean;
  children?: NavItem[];
};

export const NavItems: NavItem[] = [
  {
    name: 'home',
    layout: '/admin',
    path: '/',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    //sidebar: false,
    sidebar: isDevEnv,
  },
  {
    name: 'users',
    layout: '/admin',
    path: '/users',
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    sidebar: true,
    children: [
      {
        name: 'user',
        layout: '/admin',
        path: '/users/:id',
        sidebar: false,
      },
    ],
  },
  {
    name: 'couples',
    layout: '/admin',
    path: '/couples',
    icon: <Icon as={FaPeoplePulling} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: 'deletedUsers',
    layout: '/admin',
    path: '/deleted-users',
    icon: <Icon as={FaUsersSlash} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: 'Examples',
    layout: '/admin',
    path: '/examples/',
    icon: <Icon as={MdLayers} width="20px" height="20px" color="inherit" />,
    // sidebar: false,
    sidebar: isDevEnv,
    children: [
      {
        name: 'Tables',
        layout: '/admin',
        path: '/examples/tables',
        icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
        sidebar: true,
      },
      {
        name: 'Profile',
        layout: '/admin',
        path: '/examples/profile',
        icon: <Icon as={MdVerifiedUser} width="20px" height="20px" color="inherit" />,
        sidebar: true,
      },
    ],
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    sidebar: false,
  },
];
